.footer-container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    background-image: url('../img//footer.jpeg');
    position: static;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding: 50px 0px ;
    width: 100%;
}
.footer-container p,h2,h3{
    color: white;
    font-weight: bold;
}

/* Email */
.footer-email {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:flex-start;
    width: 100%;
    padding-left: 50px;
}
.footer-email input{
    background-color: rgba(grey, grey, grey, 0.5 );
    border-radius: 25px;
    height: 100%;
    border: none;
    padding: 10px ;
    color: black;
    margin-top: 20px;
    
}
.footer-email button{
    margin-top: 20px;
    background-color: #1AA9C1;
    border-radius: 25px;
    border: none;
    padding: 8px 7px  ;
    margin-left: -40px;
}
.arrow-icon{
    width: 22px;
}

/* img */
.img-contact-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img-contact-logo{
    width: 30%;
}


/* Contact */
.footer-contact{
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer-redes{
    margin: 0 40px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.footer-redes a{
    background-color: #3DD9CF;
    padding: 2px .5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    margin: 0 10px;
}
.a-icon-email{
  width: 40px;
}




.img-contact{
    width: 100px;
}

@media(max-width: 768px) {
    .footer-email p{
        font-size: 10px;
        margin-top: -8px;
    }
    .footer-contact h3{
        font-size: 12px;
        width: 100%;
    }
}

@media(max-width: 550px){
    .footer-container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: auto;
        padding: 0;
    }
    .footer-contact {
       justify-content: space-between;
       align-items: center;
       text-align: center;
    }

    .footer-email {
      padding: 0;
      align-items: center;
      justify-content: center;
    }

    .footer-email p{
        font-size: 15px;
        margin-top: -8px;
        margin-top: 20px;
    }
    .footer-email input{
     display: none;    
    }
    .footer-email button{
        display: none;    
    }
    .img-contact-logo{
        margin-bottom: 30px;

    }
    .a-icon-email{
        margin-left: 30px;
        width: 60px;
    }
    


}
@media(max-width: 350px){
    .footer-contact h3 {
        display: none;
    }
}
