.awssld__bullets{
    bottom: 20px   ;
    z-index: 10;
}

.awssld__bullets button{
    background-color: #D9D9D9;
}


@media(max-width: 800px){

    .awssld__bullets {
      
        display: none;
    }
}