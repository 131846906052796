.nav-container {
   display: flex;
   height: 10vh;
   width: 80%;
   margin: 10px auto 0px;
   justify-content: space-between;
   align-items: center;
   background-color: none;
   position: relative;
   z-index: 10;
  
}
.nav-container nav{
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-container h1{
    margin-right: 20px;
    font-size: 16px;
    font-weight: 600;
}
.nav-container h2{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    color: #FE2222;
    margin: 0;
}

.img-nav {
    width: 200px;
    margin-top: 40px;
}

.a-icon {
    width: 39px;
    margin: 0 5px;
}

@media(max-width: 550px){
    .nav-container{
        width: 90%;
    }
    .nav-container h1{
        display: none;
    }
    .img-nav {
        margin-top: 15px;
        width: 150px;
    }
    .nav-container nav{
        justify-content: flex-start;
        margin-right: 10px;
    }
    .a-icon {
        width: 45px;
        margin: 0 15px;
    }
}

@media(max-height: 460px){
    .img-nav {
        width: 100px;
        margin-top: 20px;
    }
}

@media(max-width: 330px){
    .img-nav {
        width: 95px;
      
    }
    .a-icon {
        width: 35px;
        margin: 0 10px
    }
}