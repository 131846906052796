.container-video1 {
    position: relative;
    overflow: hidden;
}

.container-video1 p{
    position: absolute;
    top: 18%;
    right: 16%;
    width: 70%;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #FDFDFD;
}

@media(max-width: 768px) {
   
}

 @media(max-width: 550px){
    .container-video1 p{
        font-size: 15px;
        line-height: 20px;
    }
    
 }
