.dispensers-container{
    width: 80%;
    height: 90vh;
    margin: 50px auto;
    display: flex;
    justify-content: space-between;
}
.dispensers-style{
    width: 40%;
    padding-bottom: 20px;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.dispensers-style h2{
    color: black;
    margin: 10px;
    font-size: 30px;
}
.dispensers-style h3{
    color: black;
    margin: 0px;
    font-size: 15px;
    font-weight: 500;
}
.separator-title{
    height: 1.2px;
    background-color: black;
    width: 80%;
}
.first-img{
    height: 65%;
}
.scroll-container{
    display: flex;
    width: 100%;
    height: 15%;
}
.scroll-img-container{
    height: 98%;
    margin: 0px 20px;
    border: none;
    padding: 0;
    cursor: pointer;
}
.scroll-img-container img{
    height: 100%;
}

@media(max-width: 768px) {
    .dispensers-container{
        flex-direction: column;
        height: auto;
    }
    .dispensers-style{
        width: 100%;
        height: 100vh;
        margin-top: 200px;
    }
    .first-img{
        width: auto;
        height: 500px;
    }
}

@media(max-width: 550px){
    .dispensers-container{
        width: 100%;
    }
    .dispensers-style{
        margin-top: 20px;
        box-shadow: none;
    }
}
@media(max-height: 530px){
    .first-img {
        height: 50%;
    }
    .dispensers-style h2 {
        font-size: 20px;
    }
    .dispensers-style h3 {
        color: black;
        font-size: 10px;
    }
}

@media(max-width: 324px){
    .first-img {
        height: 370px;
    }

}
