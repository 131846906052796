@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');   
html{
    width: 100vw !important;
}

body{
    margin: 0;
    width: 100vw;
    height:100%;
    background-color: white;
    font-family: 'Montserrat', sans-serif;
    overflow-x: hidden;

}