.form-container{
    display: flex;
    flex-direction: column;
    padding: 50px 100px;
}


.form-container label{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.form-container input {
    padding: 10px 10px;
    font-size: 18px;
    background-color: rgba(0, 0, 0, 0.115);
    border: none;
    border-radius: 10px;
    -webkit-box-shadow: 3px 4px 13px -5px rgba(0,0,0,0.75);
    

}

.form-container textarea {
    padding: 20px;
    font-size: 15px;
    background-color: rgba(0, 0, 0, 0.115);
    border: none;
    border-radius: 10px;
    -webkit-box-shadow: 3px 4px 13px -5px rgba(0,0,0,0.75);

}

.buttom-send{
    color: white;
    font-weight: bold;
    font-size: 20px !important;
    align-self: flex-end;
    background: #3DD9CF !important ;
    margin-top: 50px;
    width: 50%;
}

@media(max-width: 550px){

    .form-container{
        display: flex;
        flex-direction: column;
        padding: 20px 30px;
    }
}