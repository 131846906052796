.section4{
 width: 100%;
}


.img-background{
    background-image: url('../img/section4Img1.png');
    background-size: cover;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content:flex-end;
    align-items: center;
    padding-bottom: 100px;
}

.img-background h1{
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 49px;
    color: #FFFFFF;    
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 50%;
    text-align: center;
}

.img-background p{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;   
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 60%;

}

.container-zeroplastic{
    width: 100%;
    height: 80vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container-zeroplastic h2{
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: rgba(0, 0, 0, 0.75);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}

.img-zeroplastic{
    width: 100%;
    height: auto;
}

@media(max-width: 768px) {
    .img-background{
        height: 85vh;
        padding-bottom: 25px;
    }
    .container-zeroplastic{
        height: 35vh;
    }
}

@media(max-width: 550px){
    .img-background{
        height: 75vh;
  
    }
    .img-background h1{
        line-height: 30px;
        font-size: 25px;
        width: 90%;
        margin-bottom: -7px;
    }
    .img-background p{
        line-height: 20px;
        font-size: 15px;
        width: 90%;
    }
    .container-zeroplastic h2{
        font-size: 22px;
    }
}

@media(max-width: 300px){
    
    .container-zeroplastic h2{
        font-size: 20px;
    }
}



