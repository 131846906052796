.header-container{
    display: flex;
    width: 70%;
    height: 100vh;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;

}

.img-contact{
  
    width:'auto';
    height:'90vh';
}
.header-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: -100px ;
    margin-top: -50px;
    z-index: 2;
}
.header-text-p{
    font-size: 36px;
    font-weight: 600;
    line-height: 43.88px;
    margin: 0;
}
.header-text h1{
    font-weight: bold;
    line-height: 78.02px;
    font-size: 64px;
    margin: 0;
}
.header-text-p-small{
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #000000;

}
.header-text button{
    color: aliceblue;
    border: none;
    width: 40%;
    margin-top: 10px;
    padding: 5px 10px;
    background: #27978f;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 29px;
    transition: transform 200ms, box-shadow 200ms;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 29px;
}
.header-text button:hover{
    cursor: pointer;
}
.header-text button:active{
    transition: translateY(4px) transalteX(4px);
    box-shadow: 4px 4px 0px  #094c66;

    box-shadow: none;
    cursor: pointer;
}
.img-contact-header{
    width: auto;
    height: 90vh;
}


/* Tablet */

@media(max-width: 768px) {
   
    .header-text button{
        width: 75%;
        font-size: 16px;
    }
}


@media(max-width: 550px){
    .header-container{
        width: 90%;
        height: 80vh;
    }
     
    .header-text{
        width: 80vw;
    }
    .header-text h1{
        font-size: 24px;
        line-height: 20px;

    }
    .header-text-p{
        font-size: 18px;
        line-height: 30px;
        
    }
    .header-text-p-small{
        font-size: 15px;
        width: 80%;
    }
    .img-contact-header{
        height: 60vh;
    }

    .header-text button{
        position: absolute;
        bottom: 14%;
        width: 80vw;
        right: 10%;
        font-size: 20px;
    }
}

@media(max-height: 460px){
    .header-text h1{
        font-size: 24px;
        line-height: 20px;
    }
    .header-text p{
        font-size: 15px;
        line-height: 25px;
    }
    .header-text button{
       width: 50%;
    }
}