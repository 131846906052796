.section1-container{
    margin-top: -350px;
    z-index: 10;
    background-color: white;
    position: relative;
    padding-top: 100px;
}
.section1-icons-container{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    margin: 0px auto ;
    width: 100%;
    height: 100%;
}

.section1-icons{
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
    margin: 0 40px;
    padding:  100px  0;
}

.section1-icons img{
    width: 82px;
}

.section1-icons-bottle  {
    width: 69px  !important ; 
    


}

.section1-icons p{ 
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    margin: 0;
    margin-bottom: 20px;
}

.section1-icons h2{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-top: 50px;
}

.img-option{
    width: 30%;
    height: 100%;
    position: absolute;
}

.img-option label{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}

.img-option  div{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-option{
    position: absolute;
    width: 18px;
    height: 18px;
    border: none;
    border-radius: 55%;
    background: #D9D9D9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.img-option button{
    position: absolute;
    bottom: 0;
    right: 35%;
}

.option-only{
    position: absolute;
    top: -10;   
}


@media(max-width: 1100px) {
    .section1-container{
        margin-top: 0;
    }
    .section1-icons{
        padding:  10px  0;
    }
    .section1-container h1{
       padding-top: 20px;
    }
    .section1-icons-container   {
        grid-template-columns: repeat(3,2fr);
         width: 95%;
         margin-top:0px;
         align-items: center;
     
    }
    .section1-icons-container div:nth-child(4){
      transform: translateX(70%);
    }

    .section1-icons-container div:nth-child(5){
        transform: translateX(70%);
      }
}

@media(max-width: 730px){
    .section1-icons-container {       
        grid-template-columns: repeat(2,1fr);
         width: 95%;
         margin-top: 0px;
         gap: 0px;   
    }
    .section1-icons{
        margin:0;
    }
    .section1-icons h2{
        font-size: 18px;
    }
    .section1-icons p{ 
        font-size: 11px;
    
    }

    .section1-icons-container div:nth-child(4){
        transform: translateX(0%);
      }
  
    .section1-icons-container div:nth-child(5){
          transform: translateX(50%);
        }
}

@media(max-height: 460px){
    .section1-container h1{
       margin: 0;
    }
    .section1-container {
        padding-top: 40px;
     }
    .section1-icons{
        padding: 50px 0;
     }
}


@media (max-width: 320px){
.section1-icons-container {
    grid-template-columns: repeat(1,1fr);
    width: 95%;
    margin-top: 0px;
    grid-gap: 0px;
    gap: 0px;
}
.section1-icons-container div:nth-child(5){
    transform: translateX(0%);
  }
}