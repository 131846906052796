.section3-container{

  
}

.img-section3{
    width: 100vw;
    height: auto;
}

@media(max-width: 768px) {
    .section3-container{
        height: auto;
    }   
}