.section2{
    padding: 25px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    width: 100vw;
}


/* Control */
.control-container{
    width: 40%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.control-container div{
    height: 33%;
}

.control-container p{
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
  

}
.control-container img{
    width: 100px;
    margin-top: 50px;

}


/* Image */

.render-container{
    width: 45%;
    display: flex;
    position: relative;
    justify-content: center;
    height: 90%;

}

.img-render-day{
    height: 100%;
    width: auto;
}

/* Options general   */

.options-render-container{
    position: absolute;
    height: 100%;   
    width: 100%; 
}

.option-render{
    position: absolute;
    display: flex;
    align-items: center;
    
}

.option-render p{
    margin-right: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.option-render button{
    background: #D9D9D9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: none;
    height: 25px;
    width: 25px;
    border-radius: 25px;
}

.option-render button:hover{
    background: #3DD9CF;
    cursor: pointer;

}


/* position buttons */

.option1-render{
    top: 30%;
    left: 12%;
}
.option2-render{
    top: 0;
    left: 38%;
}
.option3-render{
    bottom: 15%;
    left: 10%;
}


.option-none-render{
    top: 0;
    left: -20%;
}

.button360{
    position: absolute;
    bottom: -2%;
    right: 35%;
    background: none;
    border: none;
}

.button360 img{
   width: 100px;
   cursor: pointer;
}

/*    Tablet    */

@media(max-width: 1100px){
    .section2{
        flex-direction: column-reverse;
        padding-top: 70px;
    }  
    .render-container{
        width: 45%;
        height: 60%;
    }
    .control-container{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        width: 80%;
        place-items: center;
    }
    .control-container p{
        font-size: 22px;    
        line-height: 30px;  
        text-align: center;
    }
    .control-container div:nth-child(1){

        grid-column: 3 ;
        grid-row: 1;
        margin-top: -32px;
    }
    .option1-render{
        top: 30%;
        left: -10%;
    }
    .option2-render{
        top: -10%;
        left: 25%;
    }
    .option3-render{
        bottom: 15%;
        left: -10%;
    }
    .button360{
        bottom: -14%;
        right:  30%;
        
    }
  
}

@media(max-width: 550px){
    
    .control-container{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        width: 80%;
        gap: 0;
        }
    .control-container p{
        font-size: 15px;    
        line-height: 20px;  
        text-align: center;
    }   
    .control-container div:nth-child(1){
        grid-column: 1 ;
        grid-row: 1;
    }
    .control-container div:nth-child(2){
        display: none;
    }

    .control-container div:nth-child(3){

        grid-column: 2;
        grid-row: 1;
        margin-top: 0;
    }
    .logo-section2{
        margin-top: 30px  !important;
    }
    .render-container{
        margin-left: 50px;
    }
    .option1-render{
        top: 30%;
        left: -60%;
    }
    .option2-render{
        top: -2%;
        left: 0%;
    }
    .option3-render{
        bottom: 15%;
        left: -62%;
    }
    .button360{
        bottom: -6%;
        right: 0%;
        display: none;
        
    }
    .option-none-render{
        top: -10%;
    }

}

@media(max-height: 530px){
    .control-container p {
        font-size: 20px;
        line-height: 20px;
    }
    .control-container img {
        margin-top: 20px;
    }
    .option2-render{
        top: -7%;
    }
    .button360{
        right: 15%;        
    }
}

   
